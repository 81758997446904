<!--
 * @Author: wyq
 * @Date: 2021-10-28 10:41:42
 * @LastEditTime: 2021-10-28 10:53:58
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\trainingCenter\undoneCard.vue
-->
<template>
  <v-card class="card rounded-xl">
    <div class="card-title">
      <div class="square color-warning">题型</div>
      <span ref="katex1">{{card.topLabel}}</span>
      <v-icon color="#C8C8C8" @click="openDialog(card)">icon-zhuyi</v-icon>
    </div>
    <div ref="katex" class="card-content">{{card.label}}</div>
    <div class="card-footer">
      <div>{{card.ansNum}}/{{card.allNum}}</div>
      <div class="btn-group">
        <v-btn color="warning" depressed @click="beginTest(card.id)">开始测试</v-btn>
        <v-btn v-if="card.ansNum == 0 " depressed disabled>已完成试题</v-btn>
        <v-btn v-else color="warning" outlined @click="previewTest(card.id)">已完成试题</v-btn>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    card: Object,
  },
  data() {
    return {
      show: false,
    }
  },
  watch: {
    'card.id': {
      handler() {
        setTimeout(() => {
          this.$formula(this.$refs['katex'])
          this.$formula(this.$refs['katex1'])
          setTimeout(() => {
            this.show = true
          }, 100)
        }, 100)
      },
      immediate: true,
    },
  },
  methods: {
    beginTest(id) {
      this.$emit('beginTest', id)
    },
    openDialog(val) {
      this.$emit('openDialog', val)
    },
    previewTest(id) {
      this.$emit('previewTest', id)
    },
  },
}
</script>
<style lang="scss" scoped>
.card {
  display: inline-block;
  width: calc(50% - 20px);
  margin: 10px 0;
  padding: 20px 30px;

  &:nth-of-type(2n) {
    margin-right: 40px;
  }
  &-title {
    display: flex;
    align-items: center;
    .square {
      width: 40px;
      height: 26px;
      border-radius: 6px;
      text-align: center;
      font-size: 16px;
      &.color-warning {
        background: #ffb13f;
        border: 1px solid #ffb13f;
        color: #fff;
      }
    }
    span {
      flex: 1;
      padding: 0 16px;
      font-size: 20px;
      font-family: PingFangSC, PingFangSC-Medium;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 0;
    }
  }
  &-content {
    margin: 10px 0;
    min-height: 78px;
    font-size: 16px;
    line-height: 26px;
    color: #999999;
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
  }
  .btn-group {
    .v-btn {
      width: 108px;
      height: 48px;
      border-radius: 0;
      &:first-of-type {
        padding-left: 20px;
        border-top-left-radius: 9999px;
        border-bottom-left-radius: 9999px;
      }
      &:last-of-type {
        padding-right: 20px;
        border-top-right-radius: 9999px;
        border-bottom-right-radius: 9999px;
      }
    }
  }
  ::v-deep .theme--light.v-btn.v-btn--disabled {
    color: #fff !important;
    border-color: #c5c5c5 !important;
  }
}
</style>