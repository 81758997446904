<!--
 * @Author: yy
 * @Date: 2021-09-30 15:38:23
 * @LastEditTime: 2022-04-15 15:43:47
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\views\trainingCenter\index.vue
-->
<template>
  <el-container class="adaptive-testing">
    <el-header>
      <public-header :leftArrow="false">
        <span>训练中心</span>
        <div class="revisedBtn" @click="toName('TrainingRecord')">训练记录</div>
      </public-header>
    </el-header>
    <el-main>
      <!-- <ul class="tab-bar">
        <li :class="sourceType==1?'active':''" @click="changeSourceType(1)">题型训练集</li>
        <li :class="sourceType==2?'active':''" @click="changeSourceType(2)">考点训练集</li>
      </ul> -->
      <loadMore
        ref="more"
        @query="queryList"
        :list.sync="list"
        defaultHeight="calc(100% - 1px)"
        :pageSize="1000"
      >
        <div v-for="(item, index) in list" :key="index" class="listView">
          <div class="dateTitle" v-if="item.day">{{item.day|datefmt('YYYY年M月D日')}}</div>
          <template v-for="(card, cardIndex) in item.trains">
            <component
              :is="componentName"
              :card="card"
              :key="cardIndex"
              @openDialog="openDialog"
              @previewTest="previewTest"
              @beginTest="beginTest"
            ></component>
          </template>
        </div>
        <div slot="noData">
          <img
            class="trainingCenter-noDataImg"
            src="@/assets/images/table/trainingCenter-noData.png"
          />
          <div class="trainingCenter-noDataTips">
            暂无可训练内容
            <br />请先进行自适应测试
          </div>
        </div>
      </loadMore>
      <knowledge-points-dialog :visible.sync="dialogVisible" :value="dialogValue"></knowledge-points-dialog>
    </el-main>
  </el-container>
</template>
<script>
import loadMore from '@/components/loadMore/loadMore2'
import knowledgePointsDialog from '@/components/dialogs/knowledgePointsDialog.vue'
import questionTypeCard from '@/components/trainingCenter/questionTypeCard'
import knowledgePointsCard from '@/components/trainingCenter/knowledgePointsCard'
export default {
  components: {
    loadMore,
    knowledgePointsDialog,
    questionTypeCard,
    knowledgePointsCard,
  },
  data: () => ({
    list: [],
    dialogVisible: false,
    dialogValue: {},
    sourceType: 1, //题目来源类型 1 题型 2 知识点
  }),
  computed: {
    componentName() {
      return this.sourceType == 1 ? 'questionTypeCard' : 'knowledgePointsCard'
    },
  },
  methods: {
    queryList() {
      this.$service
        .getTrainingCenterList({
          trainStatus: 0, //0-未完成训练，1-已完成训练
          sourceType: this.sourceType,
        })
        .then((res) => {
          this.$refs.more.complete(res.timeTrains)
        })
    },
    openDialog(e) {
      this.dialogVisible = true
      this.dialogValue = e
    },
    toName(name) {
      this.$router.push({
        name: name,
      })
    },
    beginTest(id) {
      this.$router.push({
        name: 'StartErrorTesting',
        query: {
          type: 'train',
          id,
        },
      })
    },
    previewTest(id) {
      this.$router.push({
        name: 'PreviewTesting',
        query: {
          type: 'finish',
          id,
        },
      })
    },
    changeSourceType(val) {
      this.sourceType = val
      this.$refs.more.reReload()
    },
  },
}
</script>
<style lang="scss" scoped>
.revisedBtn {
  float: right;
  width: 120px;
  background: #ffffff;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  text-align: center;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  margin: 12px 0 0 0;
  color: #666;
  font-family: PingFangSC-Regular, PingFang SC;
}
.dateTitle {
  font-size: 19px;
  line-height: 40px;
  color: #999999;
}
.tab-bar {
  display: flex;
  width: 100%;
  padding: 0;
  margin-bottom: 8px;
  border-radius: 24px;
  border: 1px solid #0148ff;
  overflow: hidden;
  li {
    display: block;
    flex: 1;
    height: 48px;
    text-align: center;
    line-height: 48px;
    color: #0148ff;
    font-size: 16px;
    &.active {
      background: #0148ff;
      color: #fff;
    }
  }
}
</style>

